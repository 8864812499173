import styled from '@emotion/styled';
import { getMediaImageData } from '@innedit/innedit-react';
import { DocumentType, MemoType } from '@innedit/innedit-type';
import dayjs from 'dayjs';
import compact from 'lodash/compact';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import ListCard from '../../../containers/Espace/Card';
import { ListItemProps } from '../../../containers/Espace/props';
import IconCalendar from '../../../icons/Calendar';
import IconEye from '../../../icons/Eye';
import IconEyeSlash from '../../../icons/EyeSlash';
// import IconHandShake from '../../../icons/HandShake';
import IconLevelUpAlt from '../../../icons/LevelUpAlt';
import IconRedo from '../../../icons/Redo';
import IconSort from '../../../icons/Sort';
import ImagePhoto from '../../../images/Photo';
import colors from '../../../styles/colors';
import Button from '../../Button';

const BodyCard = styled.div`
  em {
    color: ${colors.secondary[500]};
    font-weight: 600;
  }
`;

const ListCardMemo: FC<ListItemProps<MemoType>> = ({
  docId,
  changePosition,
  highlight,
  index,
  onClick,
  model,
  updateDocByIndex,
}) => {
  const [doc, setDoc] = useState<DocumentType<MemoType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return (
      <li className="flex flex-col !p-0 rounded">
        <div className="relative flex-auto flex flex-col-reverse bg-white-50 pb-full">
          <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
            <ImagePhoto color={colors.light[600]} size={100} />
          </div>
        </div>

        <div className="flex flex-row justify-around bg-white-50 border-t p-0.5 border-gray-300 rounded-b">
          <Button variant="card">
            <IconCalendar />
          </Button>
          <Button iconLeft={IconEye} variant="card" />
          <Button variant="card">
            <IconLevelUpAlt />
          </Button>
          <Button variant="card">
            <IconRedo />
          </Button>
          <Button variant="card">
            <IconSort />
          </Button>
        </div>
      </li>
    );
  }

  let media;

  const { medias } = doc;

  if (medias && medias.length > 0) {
    [media] = medias;
  }

  const image =
    media &&
    getMediaImageData({
      media,
      breakpoints: [150, 240, 320, 480, 640],
      maxWidth: 640,
      transformations: ['c_contain', 'b_ffffff', 'r_1'],
    });

  const handleHiddenOnClick = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    if (doc) {
      // TODO faire le traitement pour mettre à jour le produit
      // doc.ref.update({
      //   hidden: !doc.hidden,
      //   updatedAt: dayjs().toISOString(),
      // } as Partial<ProduitType>);
    }
  };

  const handleOnChangeDatetime = (
    event: SyntheticEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();

    const datetime = dayjs().valueOf();
    const updatedAt = dayjs().toISOString();
    // TODO faire le traitement pour mettre à jour le produit
    // doc.ref.update({
    //   datetime,
    //   updatedAt,
    // });

    if (updateDocByIndex && undefined !== index) {
      updateDocByIndex(
        index,
        {
          datetime,
          updatedAt,
        },
        0,
      );
    }
  };

  const handleOnChangeRedo = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const datetime = dayjs(doc.createdAt).valueOf();
    const updatedAt = dayjs().toISOString();
    // TODO faire le traitement pour mettre à jour le produit
    // doc.ref.update({ datetime, updatedAt });

    if (updateDocByIndex && undefined !== index) {
      updateDocByIndex(index, {
        datetime,
        updatedAt,
      });
    }
  };

  // const handleOnChangeReserved = (
  //   event: SyntheticEvent<HTMLButtonElement>,
  // ): void => {
  //   event.preventDefault();
  //   doc.ref.update({
  //     reservedAt: !doc.get('reservedAt')
  //       ? dayjs().format('YYYY-MM-DDTHH:mm')
  //       : null,
  //     updatedAt: dayjs().toISOString(),
  //   });
  // };

  // const handleSaleOnClick = (
  //   event: SyntheticEvent<HTMLButtonElement>,
  // ): void => {
  //   event.preventDefault();
  //
  //   // TODO faire le traitement pour mettre à jour le produit
  //   // const produitData = new ProduitData({ espaceId: doc.boutique });
  //   // produitData.secureUpdate({
  //   //   updatedAt: dayjs().toISOString(),
  //   // });
  // };

  const handleOnClick = (event: SyntheticEvent<HTMLElement>) => {
    onClick(event);
  };

  // Pas de stock de disponible : produit épuisé - vendu
  // if (
  //   (!item.qtyAvailable || 0 === item.qtyAvailable)
  // ) {
  //   soldOut = true;
  // }

  // Pas de canaux de distributions
  // const indisponible = !item.isOnlineStore && !item.isInPOS;

  // const price = doc.salePrice ? doc.salePrice : doc.price;
  // const prixBarre = doc.salePrice ? doc.price : doc.retailPrice || undefined;
  // let discount = null;
  //
  // if (price && prixBarre) {
  //   switch (doc.showDiscount) {
  //     case 'value':
  //       discount = `${price - prixBarre}€`;
  //       break;
  //
  //     case 'percentage':
  //     default:
  //       discount = `-${100 - (price / prixBarre) * 100}%`;
  //   }
  // }

  return (
    <ListCard
      actions={compact([
        // undefined !== doc.qtyAvailable && doc.qtyAvailable > 0
        //   ? {
        //       iconLeft: IconHandShake,
        //       onClick: handleSaleOnClick,
        //     }
        //   : null,
        {
          iconLeft: !doc.hidden ? IconEye : IconEyeSlash,
          onClick: handleHiddenOnClick,
        },
        {
          iconLeft: IconLevelUpAlt,
          onClick: handleOnChangeDatetime,
        },
        {
          iconLeft: IconRedo,
          onClick: handleOnChangeRedo,
        },
      ])}
      changePosition={changePosition}
      displayEdit={false}
      displayId={false}
      doc={doc}
      index={index}
      onClick={handleOnClick}
    >
      <figure
        className="w-full relative rounded-t cursor-pointer pb-full border-b"
        data-id={doc.id}
        onClick={handleOnClick}
        role="presentation"
      >
        <div className="absolute top-0 rounded right-0 bottom-0 left-0 flex items-center justify-center">
          <ImagePhoto color={colors.light[600]} size={100} />
        </div>
        {image && (
          <img {...image.images} alt={doc.title} className="absolute rounded" />
        )}
      </figure>
      <BodyCard className="py-0.5 px-2  text-sm">
        <h2 className="truncate text-center">{doc.title}</h2>
      </BodyCard>
    </ListCard>
  );
};

export default ListCardMemo;
