import { MemoData } from '@innedit/innedit';
import { MemoType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '../../../../../components/List/Card/Memo';
import Item from '../../../../../components/List/Item/Memo';
import CMSView from '../../../../../components/View';
import List from '../../../../../containers/Espace/List';
import params from '../../../../../params/memo.json';
import TemplateEspace from '../../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../../utils/requireEspace';
import { UserProps } from '../../../../../utils/requireUser';

const MemosPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
  params: { channelId, espaceId },
}) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List<MemoType>
          itemGrid={Card}
          itemList={Item}
          itemPathnamePrefix={location.pathname}
          model={new MemoData({ channelId, espaceId, params })}
          search={location.search}
          title={t('memos.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(MemosPage);
