/* eslint-disable max-len */
import React, { FC } from 'react';

import { IconType } from '../../components/Icon';

const ImagePhoto: FC<IconType> = ({ className, color, size = 24 }) => (
  <svg className={className} height={size} viewBox="0 0 24 24" width={size}>
    <g transform="matrix(0.499654,-3.85186e-34,-1.15556e-33,0.499654,6.00415,6.00415)">
      <path
        d="M6.5,8C7.3,8 8,8.7 8,9.5C8,10.3 7.3,11 6.5,11C5.7,11 5,10.3 5,9.5C5,8.7 5.7,8 6.5,8M6.5,7C5.1,7 4,8.1 4,9.5C4,10.9 5.1,12 6.5,12C7.9,12 9,10.9 9,9.5C9,8.1 7.9,7 6.5,7ZM22,3L2,3C0.9,3 0,3.9 0,5L0,19C0,20.1 0.9,21 2,21L22,21C23.1,21 24,20.1 24,19L24,5C24,3.9 23.1,3 22,3ZM2,5L22,5L22,14.6L17.9,10.5C16.6,9.2 14.4,9.2 13,10.5L9,14.5C8,14.1 6.8,14.3 6,15.1L2.1,19L2,19L2,5L2,5ZM7.4,16.5C7.5,16.4 7.5,16.4 7.6,16.4C7.9,16.2 8.3,16.3 8.6,16.5L11.1,19L4.9,19L7.4,16.5L7.4,16.5ZM14,19L10.7,15.7L14.5,11.9C15.1,11.3 16,11.3 16.6,11.9L22.1,17.4L22.1,19L14,19L14,19Z"
        fill={color || 'currentColor'}
      />
    </g>
  </svg>
);

export default ImagePhoto;
